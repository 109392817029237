// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="hamburguesa.png" className="App-logo" alt="logo" />
        <p>
          Principal landing / Under construction
        </p>
      </header>
    </div>
  );
}

export default App;
